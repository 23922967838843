.payed {
  display: none;
}
@media print {
  .noPrint {
    display: none;
  }
  .payed {
    display: inline;
  }
  #divcontents {
    width: 129.7%;
    margin-left: -29.6%;
    margin-top: -100px;
  }
  #expenses {
    width: 129.7%;
    margin-left: -29.6%;
    margin-top: -100px;
  }
  #report {
    margin-top: -400px;
    width: 127.7%;
    margin-left: -28.7%;
  }
  #monthlyReport {
    margin-left: 2%;
  }
  #reportTitle {
    text-align: center;
  }
  #buyContracts {
    width: 129.7%;
    margin-left: -29.6%;
    /* margin-top: -70px; */
  }
  .mystyle {
    fill: red;
  }
  .cls-1 {
    fill: #fdfce3;
  }
  .cls-2 {
    opacity: 0.69;
  }
  .cls-3 {
    fill: #139b48;
  }
  .cls-4 {
    fill: none;
  }
  .cls-4,
  .cls-5,
  .cls-6 {
    stroke: #000;
    stroke-miterlimit: 10;
    stroke-width: 0.5px;
  }
  .cls-5 {
    fill: #129b48;
  }
  .cls-6 {
    fill: #fff;
  }
  .cls-7 {
    fill: #136736;
  }
  .cls-8 {
    fill: #626466;
  }
}
